import React, { useContext, useState } from "react";
import Table from "components/common/table";
import RoleSelector from "../auth/roleSelector";
import userService from "services/users";
import { FilterButton } from "components/common/filterButton";
import MarkerContext from "components/providers/markerContext";
import { toast } from "react-toastify";

const UserDashboard = ({
  currentUser,
  users,
  approveUser,
  onUserRoleUpdate,
  onFilterChange,
  selectedFilter,
  onUserDelete,
  onEnableUser,
}) => {
  let [editingUser, setEditingUser] = useState({});

  let [roles, setUserRoles] = useState({
    Spotter: false,
    Planter: false,
    Caretaker: false,

    // Financier: false,
  });
  let [TPA, setTPA] = useState(false);
  let [superUser, setSuperUser] = useState(false);
  const markers = useContext(MarkerContext);
  const options = [
    { id: "My Zone", name: "My Zone" },
    { id: "All", name: "All" },
  ];
  const columns = [
    {
      path: "sl",
      label: "Sl.",
      content: (_, j) => j + 1,
    },
    {
      path: "zip",
      label: "Zone",
    },
    {
      path: "name",
      label: "Name",
    },
    {
      path: "email",
      label: "Email",
    },
    {
      path: "phone",
      label: "Phone",
      content: (item) => item.address.phone,
    },
    {
      path: "address",
      label: "Address",
      content: (item) => item.address.address + ", " + item.address.city,
    },

    {
      path: "roles",
      label: "Roles",
      content: (u, i) =>
        editingUser["uid"] != u["uid"] ? (
          Object.keys(u.roles).map((k) =>
            u.roles[k] ? (
              <span key={k} className="sep-comma">
                {k}
              </span>
            ) : null
          )
        ) : (
          <div>
            <RoleSelector
              roles={roles}
              onChange={onRoleChange}
              selectAll={onSelectAll}
              tpa={TPA}
              onTpaChange={onTpaChange}
              superUser={superUser}
              setSuperUser={setSuperUser}
              hideTitle
              showSuperUser
            />
          </div>
        ),
    },
    {
      path: "approved",
      label: "Approved",
      content: (u) => (u.approved ? "Yes" : "No"),
    },
    {
      path: "btn",
      label: "",
      content: (u) =>
        !u.approved && (
          <button
            className="btn btn-sm btn-primary"
            onClick={() => approveUser(u.uid)}
          >
            Approve
          </button>
        ),
    },
    {
      path: "editRoles",
      content: (u) =>
        currentUser.roles["Super Admin"] && (
          <div>
            <div>
              <button
                className="btn btn-primary btn-sm m-1"
                onClick={() => {
                  editingUser["uid"] != u["uid"]
                    ? onEditRoleClick(u)
                    : updateUserRole();
                }}
                disabled={
                  editingUser.uid === u.uid
                    ? !Object.keys(roles).find((f) => roles[f] === true) && !TPA
                    : false
                }
              >
                {editingUser["uid"] != u["uid"] ? "Edit Roles" : "Update Roles"}
              </button>
            </div>
            {editingUser["uid"] === u["uid"] && (
              <div>
                <button
                  className="btn btn-secondary btn-sm m-1"
                  onClick={onCancelEdit}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
        ),
    },
    {
      path: "deleteUser",
      label: "",
      content: (user) => (
        <i
          className={
            user.disabled
              ? "btn btn-sm btn-primary"
              : "btn btn-danger material-icons p-1"
          }
          onClick={() => {
            if (user.disabled) {
              onEnableUser(user.uid);
              userService.enableUser(user.uid);
            } else {
              let disable = false;

              if (window.confirm(`Confirm delete user ( ${user.name} )`)) {
                if (currentUser.roles["Super Admin"]) {
                  let found = Object.entries(user.roles).find(([key, value]) =>
                    checkUserContribution(key, user.uid)
                  );
                  if (found) {
                    disable = true;
                  }
                  onUserDelete(user.uid, disable);
                  userService.deleteUser(user.uid, disable);
                } else if (currentUser.roles["Super User"]) {
                  let found = Object.entries(user.roles).find(([key, value]) =>
                    checkUserContribution(key, user.uid)
                  );
                  if (found) {
                    toast.warning("Can not delete user with contributions");
                  } else {
                    onUserDelete(user.uid, false);
                    userService.deleteUser(user.uid, false);
                  }
                }
              }
            }
          }}
        >
          {user.disabled ? "Enable" : "delete"}
        </i>
      ),
      style: { width: "1%" },
    },
  ];

  const checkUserContribution = (key, uid) => {
    if (key === "TPA")
      return markers.find(
        (f) =>
          (f.status.confirmLoc && f.status.confirmLoc.uid === uid) ||
          (f.status.confirmCT && f.status.confirmCT.uid === uid) ||
          (f.status.confirmPlant && f.status.confirmPlant.uid === uid)
      );
    return markers.find(
      (f) =>
        f.status[key.toLowerCase()] && f.status[key.toLowerCase()].uid === uid
    );
  };

  const onRoleChange = ({ target }) => {
    let userRoles = { ...roles };
    userRoles[target.name] = !userRoles[target.name];
    setUserRoles(userRoles);
  };

  const onEditRoleClick = (user) => {
    let userRoles = {};
    setTPA(false);
    setSuperUser(false);
    Object.keys(user.roles).map((r) => {
      if (r != "TPA" && r != "Super Admin" && r != "Super User")
        userRoles[r] = user.roles[r];
      else if (r === "TPA" && user.roles["TPA"]) setTPA(true);
      else if (r === "Super User" && user.roles["Super User"])
        setSuperUser(true);
    });
    setUserRoles(userRoles);
    setEditingUser(user);
  };

  const updateUserRole = () => {
    // console.log(editingUser, { ...roles, TPA: TPA, "Super User": superUser });
    onUserRoleUpdate(editingUser, {
      ...roles,
      TPA: TPA,
      "Super User": superUser,
    });
    userService.updateUserRoles(editingUser["uid"], {
      ...roles,
      TPA: TPA,
      "Super User": superUser,
    });
    setEditingUser({});
  };

  const onSelectAll = (value) => {
    let userRoles = { ...roles };
    Object.keys(userRoles).map((r) => (userRoles[r] = value));
    setUserRoles(userRoles);
  };

  const onTpaChange = () => {
    let userRoles = {};

    if (TPA) {
      Object.keys(editingUser["roles"]).map((r) => {
        if (r != "TPA" && r != "Super Admin")
          userRoles[r] = editingUser["roles"][r];
        else if (editingUser["roles"]["TPA"]) {
          setTPA(true);
        }
      });
      setUserRoles(userRoles);
    } else {
      onSelectAll(false);
    }

    setTPA(!TPA);
  };

  const onCancelEdit = () => {
    let userRoles = {};

    Object.keys(editingUser["roles"]).map((r) => {
      if (r != "TPA" && r != "Super Admin" && r != "Super User")
        userRoles[r] = editingUser["roles"][r];
      else if (r === "TPA" && editingUser["roles"]["TPA"]) setTPA(true);
      else if (r === "Super User" && editingUser["roles"]["Super User"])
        setSuperUser(true);
    });
    setUserRoles(userRoles);
    setTPA(false);
    setSuperUser(false);
    setEditingUser({});
  };

  return (
    <>
      {currentUser.roles["Super Admin"] && (
        <div className="m-2">
          <FilterButton
            options={options}
            onFilterChange={onFilterChange}
            value={selectedFilter}
          />
        </div>
      )}
      <Table data={users} columns={columns} />
    </>
  );
};

export default UserDashboard;
