import React from "react";

const NumberField = React.forwardRef((props, ref) => {
  const {
    name,
    label,
    value,
    error,
    readOnly = false,
    onChange,
    hintText,
    ...rest
  } = props;

  // const [isEditing, setIsEditing] = React.useState(false);
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
      return;
    }
    const { value, selectionStart: index } = e.currentTarget;
    const regexp = /[0-9]|\.|-/;
    const dotIndex = value.indexOf(".");
    const signIndex = value.indexOf("-");
    if (!regexp.test(e.key)) {
      e.preventDefault();
      return;
    }
    if (e.key === "-" && (index !== 0 || signIndex !== -1)) {
      e.preventDefault();
      return;
    }
    if (e.key === "." && dotIndex !== -1) {
      e.preventDefault();
      return;
    }
  };

  const handleChange = (e) => {
    let value = e.target.value;
    if (value?.startsWith("00")) value = value.slice(1);
    if (value?.startsWith("-00")) value = "-" + value.slice(2);
    if (value === ".") value = "0.";
    if (value === "-.") value = "-0.";
    e.target.value = value;
    onChange(e);
  };

  return (
    <div className="">
      {label && <label htmlFor={name}>{label}</label>}
      <input
        {...rest}
        id={name}
        placeholder={hintText}
        className="form-control sm num"
        autoComplete="off"
        type="text"
        name={name}
        value={value}
        onChange={handleChange}
        onKeyPress={onKeyPress}
        ref={ref}
        readOnly={readOnly}
      />
      {error && <div className="error">{error}</div>}
    </div>
  );
});

export default NumberField;
