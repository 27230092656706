import React, { Component } from "react";
import Input from "./input";
import Password from "./password";
// import Radio from "./radio";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  handleChange = (e) => {
    const { name, value } = e.currentTarget;
    let { data } = this.state;
    data[name] = value;
    this.setState({ data });
    const schema = this.schema()[name];
    this.validateProperty(name, value, schema);
  };

  validateProperty = (name, value, schema) => {
    let { errors } = this.state;
    const { error } = schema.validate(value);
    errors[name] = error ? error.message : "";
    this.setState({ errors });
    return error;
  };

  validate = (data, schema) => {
    let errors = {};
    for (let name in schema) {
      const { error } = schema[name].validate(data[name]);
      if (error) {
        errors[name] = error.message;
        break;
      } else delete errors[name];
    }
    this.setState({ errors });
    return errors;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const errors = this.validate(this.state.data, this.schema());
    if (Object.keys(errors).length === 0)
      try {
        this.doSubmit();
      } catch {}
  };

  renderButton(label, type = "submit") {
    return (
      <button
        type={type}
        className="btn btn-sm btn-primary"
        onClick={this.handleSubmit}
      >
        {label}
      </button>
    );
  }

  renderInput = (name, label, { ...rest } = {}) => {
    const { data, errors } = this.state;
    return (
      <Input
        {...rest}
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  };
  renderPassword = (name, label, { ...rest } = {}) => {
    const { data, errors } = this.state;
    return (
      <Password
        {...rest}
        name={name}
        label={label}
        value={data[name]}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  };

  // renderRadio(name, label, options, { ...rest } = {}) {
  //   const { data } = this.state;
  //   return (
  //     <Radio
  //       {...rest}
  //       label={label}
  //       name={name}
  //       value={data[name]}
  //       options={options}
  //       onChange={this.handleChange}
  //     />
  //   );
  // }
}

export default Form;
