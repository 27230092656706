import React, { useContext, useRef, useState } from "react";
import AuthContext from "components/providers/authContext";
import User from "./user";
import { Link, NavLink, useLocation } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import useOnClickOutside from "hooks/useOnClickOutside";
import "styles/nav.css";
import Social from "./social";

const NavBar = () => {
  const [show, setShow] = useState(false);
  const navRef = useRef(null);
  const btnRef = useRef(null);

  const auth = useContext(AuthContext);
  const location = useLocation();
  const user = auth.user;

  const roles = user?.roles;

  useOnClickOutside([navRef, btnRef], () => setShow(false));

  return (
    <header>
      <span
        ref={btnRef}
        role="button"
        onClick={() => setShow(!show)}
        style={{ display: "flex", fontSize: "1.7em" }}
        tabIndex="0"
        className="material-icons p-1"
      >
        menu
      </span>
      <div>
        <Link to="/" className="logo item" replace>
          MoreTreesNow
        </Link>
      </div>
      {roles && <User user={user} logout={auth.logout} />}
      <CSSTransition in={show} timeout={200} unmountOnExit nodeRef={navRef}>
        <nav ref={navRef} className="drawer">
          <ul onClick={() => setShow(false)}>
            {!roles && (
              <>
                <li>
                  <NavLink
                    to={{ pathname: "/login", state: { background: location } }}
                    className="item"
                  >
                    Login
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={{
                      pathname: "/register",
                      state: { background: location },
                    }}
                    className="item"
                  >
                    Register
                  </NavLink>
                </li>
              </>
            )}
            {(roles?.TPA || roles?.["Super User"]) &&
              location.pathname !== "/dashboard" && (
                <li>
                  <NavLink to="/dashboard" className="item">
                    Dashboard
                  </NavLink>
                </li>
              )}

            {(roles?.TPA || roles?.["Super User"]) &&
              location.pathname === "/dashboard" && (
                <li>
                  <NavLink to="/" className="item">
                    Home
                  </NavLink>
                </li>
              )}

            <li>
              <NavLink to="/" className="item">
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink
                to={{ pathname: "/help", state: { background: location } }}
                className="item"
              >
                Help
              </NavLink>
            </li>
            <li>
              <NavLink to="/" className="item">
                About
              </NavLink>
            </li>
            <li>
              <Social />
            </li>
          </ul>
        </nav>
      </CSSTransition>
    </header>
  );
};

export default NavBar;
