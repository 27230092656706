import React, { useRef, useContext } from "react";
import { CSSTransition } from "react-transition-group";
import { Link, useLocation } from "react-router-dom";
import useOnClickOutside from "hooks/useOnClickOutside";
import MarkerContext from "components/providers/markerContext";

const User = ({ user, logout }) => {
  const [showDetails, setShowDetails] = React.useState(false);
  const nodeRef = useRef(null);
  const triggerRef = useRef(null);
  const markers = useContext(MarkerContext);
  const location = useLocation();

  const { name, email, zip, photoURL, roles, uid } = user;
  useOnClickOutside([nodeRef, triggerRef], () => setShowDetails(false));

  const approved =
    !(roles.Caretaker || roles.Planter || roles.TPA) || user.approved;

  const contrib = (key) => {
    if (key === "TPA")
      return markers.filter(
        (f) =>
          (f.status.confirmLoc && f.status.confirmLoc.uid === uid) ||
          (f.status.confirmCT && f.status.confirmCT.uid === uid) ||
          (f.status.confirmPlant && f.status.confirmPlant.uid === uid)
      ).length;
    return markers.filter(
      (f) =>
        f.status[key.toLowerCase()] && f.status[key.toLowerCase()].uid === uid
    ).length;
  };
  return (
    <div className="position-relative">
      <span
        tabIndex="0"
        role="button"
        className="d-flex pointer"
        ref={triggerRef}
        onClick={() => setShowDetails(!showDetails)}
        aria-label="Profile"
      >
        {photoURL ? (
          <img src={photoURL} alt="Profile" className="img-rounded-sm" />
        ) : (
          <i className="material-icons" style={{ fontSize: "36px" }}>
            account_circle
          </i>
        )}
      </span>
      <CSSTransition
        in={showDetails}
        timeout={200}
        unmountOnExit
        nodeRef={nodeRef}
      >
        <div className="dd" ref={nodeRef}>
          <h4>{name}</h4>
          <div>{email}</div>
          <div>Pincode: {zip}</div>
          <div>{approved ? "Approved" : "Pending Approval"}</div>
          <div className="hl" />
          <div className="my-2 grid-even">
            <div className="text-secondary">Roles</div>
            <div className="text-secondary">Contributions</div>
            {Object.entries(roles).map(
              ([key, value]) =>
                value && (
                  <React.Fragment key={key}>
                    <div>{key}</div>
                    <div>{contrib(key)}</div>
                  </React.Fragment>
                )
            )}
          </div>
          <div className="hl" />

          <div className="d-flex justify-content-between">
            <Link
              to={{
                pathname: "/account",
                state: { background: location },
              }}
            >
              <span
                className="material-icons pointer"
                style={{ fontSize: "30px" }}
                role="button"
                tabIndex="0"
              >
                manage_accounts
              </span>
            </Link>
            <button className="btn btn-sm btn-outline-danger" onClick={logout}>
              Logout
            </button>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default User;
