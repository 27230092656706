import React, { useEffect, useState } from "react";
import userService from "services/users";
const PhotoLoader = ({ uid }) => {
  const [photoUrl, setPhotoUrl] = useState("");
  useEffect(async () => {
    let photoUrl = await userService.getPhotoUrl(uid);
    setPhotoUrl(photoUrl);
  });
  return (
    <div>
      {photoUrl ? (
        <img
          src={photoUrl}
          alt="Profile"
          className="img-rounded-sm"
          style={{ fontSize: "36px" }}
        />
      ) : (
        <i className="material-icons" style={{ fontSize: "36px" }}>
          account_circle
        </i>
      )}
    </div>
  );
};

export default PhotoLoader;
