import Joi from "joi";

const schemas = {
  authSchema: {
    password: Joi.string().min(6).label("Password"),
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .label("Email"),
  },
  commonSchema: {
    zip: Joi.number().label("Pincode/Zip"),
    name: Joi.string()
      .label("Name")
      .pattern(/\w/)
      .messages({ "string.pattern.base": "Enter a valid {{#label}}" }),
  },
  addressSchema: {
    phone: Joi.string()
      .length(10)
      .label("Phone Number")
      .pattern(/^\d+$/)
      .messages({ "string.pattern.base": "Enter a valid {{#label}}" }),
    address: Joi.string()
      .label("Address")
      .pattern(/\w/)
      .messages({ "string.pattern.base": "Enter a valid {{#label}}" }),
    city: Joi.string()
      .label("City")
      .pattern(/\w/)
      .messages({ "string.pattern.base": "Enter a valid {{#label}}" }),
    state: Joi.string()
      .label("State")
      .pattern(/\w/)
      .messages({ "string.pattern.base": "Enter a valid {{#label}}" }),
  },
};

export default schemas;
