import React, { useContext } from "react";
import MarkerContext from "../providers/markerContext";
import { icons } from "icons/markers";

const TreeCounter = () => {
  const markers = useContext(MarkerContext);

  let counts = {
    YW: {
      count: 0,
      label: "Spotted",
    },
    RD: {
      count: 0,
      label: "Confirmed",
    },
    LBW: { count: 0, label: "Waiting For Volunteers" },

    BL: {
      count: 0,
      label: "Ready To Plant",
    },
    LGN: { count: 0, label: "Planted, Yet To Confirm" },
    GN: {
      count: 0,
      label: "Planted Trees",
    },
    GY: {
      count: 0,
      label: "Dead",
    },
  };
  for (let i = 0; i < markers.length; i++) {
    counts[markers[i].statusColor].count++;
  }
  return (
    <div className="tree-sticker">
      {Object.keys(counts).map((key, i) => (
        <React.Fragment key={key}>
          {i !== 0 && <div className="vl" />}
          <TreeSticker name={key} counts={counts} />
        </React.Fragment>
      ))}
    </div>
  );
};

const TreeSticker = ({ name, counts }) => (
  <div className="sticker">
    <img className="sticker-img" src={icons[name]} alt={name}></img>
    <span className="sticker-count">{counts[name].count}</span>
    <span className="sticker-label">{counts[name].label}</span>
  </div>
);

export default TreeCounter;
