import http from "./http";
import { auth, db, storage } from "services/firebase";
import { api } from "config";

const userDb = db.collection("users");
let userPhotoUrl = [];
let userPhotoRefs = [];

async function createUser(email, password, data, roles, image) {
  try {
    const { user } = await auth.createUserWithEmailAndPassword(email, password);
    await updateUser(user, data, image);
    await uploadUserRoles(user, roles);
    await user.sendEmailVerification();
    return { user, error: null };
  } catch (ex) {
    handleError(ex);
    return { user: null, error: ex };
  }
}

async function updateUser(user, data, image) {
  user = user == null ? auth.currentUser : user;
  await userDb.doc(user.uid).set(
    {
      ...data,
    },
    { merge: true }
  );
  if (image) {
    let ss = await storage.ref(`/profilePhotos/${user.uid}.jpg`).put(image);
    let url = await ss.ref.getDownloadURL();
    await user.updateProfile({
      photoURL: url,
      displayName: data.name,
    });
  } else
    await user.updateProfile({
      displayName: data.name,
    });
  return;
}

async function uploadUserRoles(user, roles) {
  try {
    const res = await http.post(api.server + api.register, {
      uid: user.uid,
      roles,
    });
    console.log(res);
    return null;
  } catch (ex) {
    handleError(ex);
    return { error: ex };
  }
}

async function login(email, password) {
  try {
    const { user } = await auth.signInWithEmailAndPassword(email, password);
    // .catch((ex) => handleError(ex));
    if (user && !user.emailVerified) {
      const r = window.confirm(
        "Verify your email to login. Press OK to send verificatiion link again."
      );
      if (r) user.sendEmailVerification().catch((ex) => handleError(ex));
      auth.signOut();
      return { user: null };
    }
    return { user, error: null };
  } catch (ex) {
    handleError(ex);
    return { user: null, error: ex };
  }
}

async function sendResetPasswordLink(email) {
  try {
    await auth.sendPasswordResetEmail(email);
    alert(`Reset Link has been Sent to ${email}`);
  } catch (error) {}
}

async function getUserByEmail(email) {
  let userData = await userDb.where("email", "==", email).get();
  if (userData.docs.length > 0) {
    await sendResetPasswordLink(email);
    return true;
  } else {
    alert(`${email} Not Registered`);
    return false;
  }
}

async function getUserData(user) {
  try {
    const { claims } = await auth.currentUser.getIdTokenResult();
    if (!claims.roles)
      return { userData: null, error: new Error("User roles not found") };
    let doc = await userDb.doc(user.uid).get();
    // .catch((ex) => handleError(ex));

    const userData = {
      uid: user.uid,
      email: user.email,
      photoURL: user.photoURL,
      roles: claims.roles,
      approved: claims.approved,
      ...doc.data(),
    };
    return { userData, error: null };
  } catch (ex) {
    handleError(ex);
    return { userData: null, error: ex };
  }
}

async function getUserClaims(uids) {
  try {
    const { data } = await http.post(api.server + api.getUserClaims, { uids });
    return { data, error: null };
  } catch (ex) {
    return { data: null, error: ex };
  }
}

async function updateUserRoles(uid, roles) {
  try {
    await http.post(api.server + api.updateUserRoles, {
      uid: uid,
      userRoles: roles,
    });
  } catch (error) {
    handleError(error);
  }
}

async function deleteUser(uid, disable) {
  try {
    await http.post(api.server + api.deleteUser, { uid, disable });
    if (!disable) {
      await userDb.doc(uid).delete();
    }
  } catch (error) {
    handleError(error);
  }
}

async function enableUser(uid) {
  try {
    await http.post(api.server + api.enableUser, { uid });
  } catch (error) {
    handleError(error);
  }
}

async function getPhotoUrl(uid) {
  try {
    let photoURL = await storage
      .ref(`/profilePhotos/${uid}.jpg`)
      .getDownloadURL();
    return photoURL;
  } catch (error) {
    // console.log(error);
    return null;
  }
}

function handleError(ex) {
  switch (ex.code) {
    case "auth/user-not-found":
      alert("User not found!");
      break;
    case "auth/wrong-password":
      alert("Wrong Password!");
      break;
    case "auth/email-already-in-use":
      alert("The email address already has an account.");
      break;
    case "auth/invalid-email":
      alert("The email address is invalid.");
      break;
    case "auth/network-request-failed":
      alert("A network error has occurred.");
      break;
    default:
      console.error(ex);
      break;
  }
}

const userService = {
  createUser,
  getUserData,
  login,
  updateUser,
  getUserClaims,
  handleError,
  updateUserRoles,
  getUserByEmail,
  deleteUser,
  getPhotoUrl,
  enableUser,
};

export default userService;
