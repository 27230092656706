import Input from "components/common/input";
import React from "react";

const TreeDetails = ({ tree }) => {
  return (
    <>
      <Input label={"Tree Name"} value={tree.treeName} readOnly />
      <Input
        label={"Tree Name (Local Language)"}
        value={tree.localName}
        readOnly
      />
      <Input label={"Botanical Name"} value={tree.botanicalName} readOnly />
    </>
  );
};

export default TreeDetails;
