import Input from "components/common/input";
import React, { useState, useEffect } from "react";
import RoleActions from "./roleActions";
import TreeStatus from "./treeStatus";
import _ from "lodash";
import Dropdown from "components/common/dropdown";
import TextArea from "components/common/textArea";
import Radio from "components/common/radio";

const TreeUpdate = ({
  roles,
  tree,
  addMarker,
  removeMarker,
  updateMarker,
  uid,
  zip,
  userName,
  approved,
  readonly,
}) => {
  const [names, setNames] = useState({
    treeName: "",
    localName: "",
    botanicalName: "",
    contributedTo: "",
    institution: "",
    comment: "",
  });
  // const [contributedTo, setContributedTo] = useState("");
  const [status, setStatus] = useState({});

  const list = [
    { id: "Personal", name: "Personal" },
    { id: "Social", name: "Social" },
    { id: "In memory of", name: "In memory of" },
    { id: "Celebrating", name: "Celebrating" },
    { id: "Others", name: "Others" },
  ];

  useEffect(() => {
    if (tree) {
      setStatus(_.cloneDeep(tree.status));
      setNames({
        treeName: tree.treeName,
        localName: tree.localName,
        botanicalName: tree.botanicalName,
        contributedTo: tree.contributedTo,
        institution: tree.institution,
        comment: tree.comment,
      });
    } else {
      setNames({
        treeName: "",
        localName: "",
        botanicalName: "",
        contributedTo: "",
        institution: "",
        comment: "",
      });
      setStatus({});
    }
  }, [tree]);

  function onNameChange({ target }) {
    let nextNames = { ...names };
    nextNames[target.name] = target.value;
    setNames(nextNames);
  }

  function statusChanged() {
    return status.statusCodes
      ? status.statusCodes.find((f) => !tree.status.statusCodes.includes(f))
      : null;
  }

  return (
    <>
      {(tree ||
        roles.Spotter ||
        roles.TPA ||
        roles["Super Admin"] ||
        roles["Super User"]) && (
        <>
          <Input
            label={"Name in Local Language"}
            value={names.localName}
            name="localName"
            onChange={onNameChange}
            readOnly={readonly}
            required
          />
          <Input
            label={"Tree Name"}
            name="treeName"
            value={names.treeName}
            onChange={onNameChange}
            readOnly={readonly}
          />
          <Input
            label={"Botanical Name"}
            name="botanicalName"
            value={names.botanicalName}
            onChange={onNameChange}
            readOnly={readonly}
          />

          {!readonly ? (
            <Radio
              readOnly={readonly}
              options={list}
              name="contributedTo"
              label="Contributed To"
              onChange={onNameChange}
              value={names.contributedTo ? names.contributedTo : ""}
            />
          ) : (
            // <Dropdown
            //   readOnly={readonly}
            //   options={list}
            //   name="contributedTo"
            //   label="Contributed To"
            //   onChange={onNameChange}
            //   value={names.contributedTo}
            // />
            <Input
              readOnly={true}
              name="contributedTo"
              label="Contributed To"
              onChange={() => {}}
              value={names.contributedTo}
            />
          )}
          <TextArea
            readOnly={readonly}
            name="comment"
            label="Comment"
            value={names.comment}
            onChange={onNameChange}
          />
          <Input
            readOnly={readonly}
            name="institution"
            label="Institution"
            value={names.institution}
            onChange={onNameChange}
          />
        </>
      )}
      {}
      {tree && (
        <div>
          Tree Status:
          {tree.status.statusCodes.map((code, index) => (
            <div key={index}>
              <TreeStatus code={code} status={tree.status} />
            </div>
          ))}
          {approved && (
            <RoleActions
              roles={roles}
              status={status}
              setStatus={setStatus}
              uid={uid}
              zip={zip}
              userName={userName}
              tree={tree}
            />
          )}
        </div>
      )}

      <div>
        {tree &&
          (roles.TPA ||
            roles["Super Admin"] ||
            roles["Super User"] ||
            (tree.status.statusCodes.includes(11) &&
              tree.status.spotter === uid)) && (
            <button
              className="btn btn-sm btn-danger mt-2 me-2"
              onClick={removeMarker}
            >
              Remove
            </button>
          )}
        {tree
          ? (statusChanged() || !readonly) && ( //tree.status.code != status.code
              <button
                type="button"
                className="btn btn-sm btn-success mt-2"
                onClick={() => {
                  updateMarker(names, status);
                }}
              >
                Update
              </button>
            )
          : (roles.Spotter ||
              roles.TPA ||
              roles["Super Admin"] ||
              roles["Super User"]) && (
              <button
                className="btn btn-sm btn-success mt-2"
                onClick={() => {
                  let statusCode =
                    roles.TPA || roles["Super Admin"] || roles["Super User"]
                      ? 12
                      : 11;

                  addMarker(names, {
                    spotter: { uid: uid, name: userName },
                    confirmLoc:
                      roles.TPA || roles["Super Admin"] || roles["Super User"]
                        ? { uid: uid, name: userName }
                        : { uid: "", name: "" },
                    statusCodes: [statusCode],
                  });
                }}
              >
                Add
              </button>
            )}
      </div>
    </>
  );
};

export default TreeUpdate;
