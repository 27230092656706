import { api } from "config";
import http from "./http";
import treeService from "./trees";

export async function approveUser(uid) {
  const { data } = await http.post(api.server + api.approveUser, { uid });
  console.log(data);
  return;
}
function removeElement(array, value) {
  return array.filter((v) => v !== value);
}

export function tpaAction(confirm, tree, uid, userName, statusCode) {
  const id = tree.id;
  let status = { ...tree.status };
  status.statusCodes = removeElement(status.statusCodes, statusCode);
  switch (statusCode) {
    case 11:
      if (!confirm) {
        if (window.confirm("Are you sure? Marker will be removed."))
          treeService.treeAction("DELETE", { id });
        return;
      }
      status.confirmLoc = { uid: uid, name: userName };
      // status.confirmLocName = userName;
      // status.code += 1;
      statusCode += 1;
      break;

    case 21:
      // status.caretaker = confirm ? status.caretaker : "";
      // status.caretakerName = confirm ? userName : "";
      // status.code = confirm ? status.code + 1 : 12;
      if (confirm) {
        statusCode = statusCode + 1;
        status.confirmCT = { uid: uid, name: userName };
        // status.caretaker = status.caretaker;
      } else {
        status.statusCodes = removeElement(status.statusCodes, 21);
        status.caretaker = null;
      }
      break;

    case 31:
      // status.confirmPlant = confirm ? uid : "";
      // status.planter = confirm ? status.planter : "";
      // status.planterName = confirm ? userName : "";
      if (confirm) {
        statusCode = statusCode + 1;
        status.confirmPlanter = { uid: uid, name: userName };
        // status.planter = status.planter;
      } else {
        status.statusCodes = removeElement(status.statusCodes, 31);
        statusCode = 0;
        // status.planterName = "";
        status.planter = null;
      }
      break;

    case 12:
      if (window.confirm("Are you sure? Marker will be removed."))
        treeService.treeAction("DELETE", { id });
      return;

    case 22:
      status.confirmCT = { uid: uid, name: userName };
      // status.confirmCT = userName;
      statusCode -= 2;
      break;

    case 20:
      status.confirmCT = { uid: uid, name: userName };
      // status.confirmCT = userName;
      statusCode += 2;
      break;

    case 30:
      status.confirmPlant = { uid: uid, name: userName };

      // status.confirmPlant = userName;
      statusCode += 2;
      break;
    case 51:
      if (confirm) {
        statusCode += 1;
        status.confirmPlantTree = { uid: uid, name: userName };
      } else {
        status.statusCodes = removeElement(status.statusCodes, 51);
        status.plantTree = null;
      }
      break;
    default:
      break;
  }
  if (confirm) status.statusCodes.push(statusCode);

  treeService.treeAction("UPDATE", { id, status }, true);
}

// export function getStatus(status) {
//   switch (status.code) {
//     case 11:
//       return {
//         name: "Spotted",
//         uid: status.spotter,
//       };
//     case 21:
//       return {
//         name: "Caretaker Assigned",
//         uid: status.caretaker,
//       };
//     case 31:
//       return {
//         name: "Planted",
//         uid: status.planter,
//       };

//     case 12:
//       return {
//         name: "Location Confirmed",
//         uid: status.spotter,
//       };

//     case 22:
//       return {
//         name: "Caretaker Confirmed",
//         uid: status.caretaker,
//       };

//     case 32:
//       return {
//         name: "Plant Confirmed",
//         uid: status.planter,
//       };

//     case 30:
//       return {
//         name: "Plant Rejected",
//         uid: status.confirmPlant,
//       };

//     case 20:
//       return {
//         name: "Caretaker Rejected",
//         uid: status.confirmCT,
//       };

//     default:
//       return {
//         name: "Unknown",
//         uid: null,
//       };
//   }
// }

export const getTPAUid = (tree) => {
  if (tree.status.statusCodes.includes(32)) return tree.status.confirmPlanter;
  if (tree.status.statusCodes.includes(22)) return tree.status.confirmCT;
  if (
    tree.status.statusCodes.includes(31) ||
    tree.status.statusCodes.includes(12) ||
    tree.status.statusCodes.includes(21)
  )
    return tree.status.confirmLoc;
};
