import React, { useEffect, useRef } from "react";
import { CSSTransition } from "react-transition-group";
import "styles/side.css";

const SideWindow = ({ children, onClose, show }) => {
  const ref = useRef(null);
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CSSTransition in={show} timeout={300} unmountOnExit nodeRef={ref}>
      <aside ref={ref} className="side-window">
        <div className="modal__header">
          <span className="close-icon" onClick={onClose}>
            <svg
              className="close"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
          </span>
        </div>
        <div className="side-window__content scroll">{children}</div>
      </aside>
    </CSSTransition>
  );
};

export default SideWindow;
