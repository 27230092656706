import { useReducer } from "react";

const defaultCenter = {
  lat: 12.967561073450515,
  lng: 77.58099664677148,
};

const initialState = {
  center: defaultCenter,
  location: null,
  selectedPosition: null,
  selectedMarker: null,
  showInfo: false,
  treeId: null,
  coord: { lat: "", lng: "" },
};

function reducer(state, [type, payload]) {
  switch (type) {
    case "MAP_CLICK":
      if (state.showInfo)
        return {
          ...state,
          selectedMarker: payload,
          selectedPosition: payload,
          treeId: null,
          coord: payload,
        };
      return { ...state, selectedPosition: payload, coord: payload };
    case "MARKER_CLICK":
      return {
        ...state,
        selectedMarker: payload,
        showInfo: true,
        treeId: null,
      };
    case "TREE_CLICK":
      return {
        ...state,
        selectedPosition: null,
        selectedMarker: payload.position,
        showInfo: true,
        treeId: payload.id,
      };
    case "CLOSE_INFO":
      return {
        ...state,
        showInfo: false,
        treeId: null,
        selectedMarker: null,
      };
    case "SET_LOC":
      return { ...state, center: payload, location: payload };
    case "COORD_CHANGE":
      let nextCoord = { ...state.coord };
      nextCoord[payload.name] = Number(payload.value);
      return { ...state, coord: nextCoord };
    case "SET_COORD":
      return { ...state, selectedPosition: state.coord };
    default:
      return state;
  }
}
const useMapState = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return [state, dispatch];
};

export default useMapState;
