import React from "react";
import fb from "icons/fb.svg";
import insta from "icons/insta.svg";
import twitter from "icons/twitter.svg";

const Social = () => {
  return (
    <div className="d-flex justify-content-end align-items-center gap-3">
      <a href="http://facebook.com" target="_blank" rel="noreferrer">
        <img src={fb} alt="Facebook" style={{ width: "1.5em" }} />
      </a>
      <a href="http://instagram.com" target="_blank" rel="noreferrer">
        <img src={insta} alt="Instagram" style={{ width: "1.5em" }} />
      </a>
      <a href="http://twitter.com" target="_blank" rel="noreferrer">
        <img src={twitter} alt="twitter" style={{ width: "1.5em" }} />
      </a>
    </div>
  );
};
export default Social;
