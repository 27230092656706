import React from "react";

const TextArea = React.forwardRef((props, ref) => {
  const { name, label, error, hintText, handleBlur, ...rest } = props;
  return (
    <div>
      {label && <label htmlFor={name}>{label}</label>}

      <textarea
        {...rest}
        ref={ref}
        onBlur={handleBlur}
        name={name}
        id={name}
        className="form-control"
        placeholder={hintText}
      />
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
});
export default TextArea;
