import { useEffect } from "react";

export default function useOnClickOutside(refs = [], handler) {
  useEffect(() => {
    const listener = (event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (refs.some((ref) => ref.current && ref.current.contains(event.target)))
        return;
      handler(event);
    };
    document.addEventListener("mousedown", listener, { capture: true });
    document.addEventListener("touchstart", listener, { capture: true });
    return () => {
      document.removeEventListener("mousedown", listener, { capture: true });
      document.removeEventListener("touchstart", listener, { capture: true });
    };
  }, [refs, handler]);
}
