import React from "react";
import Input from "./input";

const Address = ({ address, onChange, errors, required }) => {
  const star = required ? " *" : "";
  return (
    <>
      <Input
        label={"Address" + star}
        value={address.address}
        name="address"
        onChange={onChange}
        error={errors["address"]}
      />
      <Input
        label={"City" + star}
        value={address.city}
        name="city"
        onChange={onChange}
        error={errors["city"]}
      />
      <Input
        label={"State" + star}
        value={address.state}
        name="state"
        onChange={onChange}
        error={errors["state"]}
      />
      <Input
        label={"Phone Number" + star}
        value={address.phone}
        name="phone"
        onChange={onChange}
        error={errors["phone"]}
      />
    </>
  );
};

export default Address;
