import { db } from "./firebase";
// import firebase from "firebase/app";

const treesRef = db.collection("trees");

export function treeSnapshot(id, observer) {
  return treesRef.doc(id).onSnapshot(observer);
}

// function filterStatusCode(array, statusCode) {
//   return array.filter((f) => f !== statusCode);
// }

export async function treeAction(action, payload = {}, isTapAction) {
  switch (action) {
    case "ADD":
      let tree = {
        ...payload,
        statusColor: getStatusColor(payload.status.statusCodes),
      };
      const docRef = await treesRef.add(tree);
      return { ...tree, id: docRef.id };
    case "UPDATE":
      // payload.statusCodes.push(statusCode);
      // payload.statusCodes = filterStatusCode(payload.statusCodes, previousCode);
      let nextTree = {
        ...payload,
        statusColor: getStatusColor(payload.status.statusCodes),
      };

      if (isTapAction) {
        treesRef.doc(payload.id).update({
          ...nextTree,
          // permissions: firebase.firestore.FieldValue.arrayRemove(previousCode),
          // confirmed: firebase.firestore.FieldValue.arrayUnion(statusCode),
        });
      } else {
        treesRef.doc(payload.id).update({
          ...nextTree,
          // permissions: firebase.firestore.FieldValue.arrayUnion(statusCode),
        });
      }
      // treesRef.doc(payload.id).set(nextTree, { merge: true });
      return nextTree;
    case "DELETE":
      treesRef.doc(payload.id).delete();
      break;
    default:
      return null;
  }
}

export function getStatusColor(codes) {
  if (codes.includes(52)) return "GN";
  if (codes.includes(51)) return "LGN";
  if (codes.includes(22) && codes.includes(32)) return "BL";
  if (codes.includes(22) || codes.includes(32)) return "LBW";
  if (codes.includes(12)) return "RD";
  if (codes.includes(11)) return "YW";
  return "GY";
}

const treeService = {
  treeAction,
  treeSnapshot,
  getStatusColor,
};

export default treeService;
