import React from "react";

const SearchBox = ({ value, onChange, placeholder }) => {
  return (
    <div className="form-control d-flex search-box">
      <input
        type="text"
        name="query"
        className="search-box__input"
        placeholder={placeholder ? placeholder : "Search.."}
        value={value}
        onChange={(e) => onChange(e.currentTarget.value)}
      />
      <i type="button" onClick={() => onChange("")} className="material-icons">
        close
      </i>
    </div>
  );
};

export default SearchBox;
