import React from "react";
import AuthContext from "./authContext";
import userService from "services/users";
import { auth } from "services/firebase";

class AuthProvider extends React.Component {
  state = {
    user: null,
  };

  componentDidMount() {
    this.unsubAuth = auth.onAuthStateChanged((user) => {
      user && this.handleUser(user);
    });
  }

  componentWillUnmount() {
    this.unsubAuth && this.unsubAuth();
  }

  register = async (email, password, roles, data, image) => {
    this.unsubAuth && this.unsubAuth();

    const { error } = await userService.createUser(
      email,
      password,
      data,
      roles,
      image
    );
    auth.signOut();
    if (error) return false;
    alert(
      "Registration successful. An Email has been sent to verify the email address."
    );
    return true;
  };

  handleUser = async (user) => {
    try {
      const { userData } = await userService.getUserData(
        user || auth.currentUser
      );
      if (!userData) {
        auth.signOut();
        return false;
      }
      this.setState({ user: userData });
      return true;
    } catch (ex) {
      console.log(ex);
      return false;
    }
  };

  login = async (email, password) => {
    const { user } = await userService.login(email, password);
    if (!user) return false;
    if (await this.handleUser(user)) return true;
    return false;
  };

  logout = async () => {
    await auth.signOut();
    this.setState({ user: null });
  };

  render() {
    const { user } = this.state;
    return (
      <AuthContext.Provider
        value={{
          user: user,
          register: this.register,
          handleUser: this.handleUser,
          login: this.login,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthProvider;
