import "bootstrap/dist/css/bootstrap.css";
import "../styles/App.css";
import "react-toastify/dist/ReactToastify.css";
import "font-awesome/css/font-awesome.css";
import "../styles/material-icons/material-icons.css";
import "../styles/select.css";

import React, { createRef } from "react";
import MarkerProvider from "./providers/markerProvider";
import AuthProvider from "./providers/authProvider";
import { Switch, useLocation, Route, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import NavBar from "./header/navBar";
import MapLoader from "./map/mapLoader";
import Register from "./auth/register";
import Login from "./auth/login";
import Dashboard from "./tpa/dashboard";
import Account from "./auth/account";
import Help from "./help";
import Modal from "./common/modal";

import { ToastContainer, Slide } from "react-toastify";
import ForgottenPassword from "./auth/forgotPassWord";

const modals = [
  { path: "/login", Component: Login, ref: createRef() },
  { path: "/register", Component: Register, ref: createRef() },
  { path: "/help", Component: Help, ref: createRef() },
  { path: "/account", Component: Account, ref: createRef() },
  {
    path: "/forgotPassword",
    Component: ForgottenPassword,
    ref: createRef(),
  },
];

function App() {
  const location = useLocation();
  const history = useHistory();
  const background = location.state && location.state.background;

  return (
    <AuthProvider>
      <MarkerProvider>
        <NavBar />
        <ToastContainer
          autoClose={2000}
          closeButton={false}
          transition={Slide}
          limit={2}
          closeOnClick
        />
        <Switch location={background || location}>
          <Route exact path="/" component={MapLoader} />
          <Route exact path="/dashboard" component={Dashboard} />
          {/* <Route exact path="/account" component={Account} /> */}
        </Switch>

        {modals.map(({ path, Component, ref }, i) => (
          <Route key={path} exact path={path}>
            {(props) => (
              <CSSTransition
                in={props.match != null}
                timeout={300}
                unmountOnExit
                nodeRef={ref}
              >
                <Modal onClose={() => history.goBack()} ref={ref}>
                  <Component {...props} />
                </Modal>
              </CSSTransition>
            )}
          </Route>
        ))}
      </MarkerProvider>
    </AuthProvider>
  );
}

export default App;
