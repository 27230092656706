import React from "react";

const CheckBox = ({ name, checked, label, ...rest }) => {
  return (
    <div className="form-check">
      <input
        {...rest}
        id={name}
        name={name}
        checked={checked}
        type="checkbox"
        className="form-check-input"
      />
      {label && (
        <label
          htmlFor={name}
          className="form-check-label"
          style={{ whiteSpace: "nowrap" }}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export default CheckBox;
