import React, { Component } from "react";
import MarkerContext from "./markerContext";
import { db } from "services/firebase";

const collectionRef = db.collection("trees");

class MarkerProvider extends Component {
  state = {
    markers: [],
  };

  componentDidMount() {
    this.unsubscribe = collectionRef.onSnapshot(this.observer);
  }
  componentWillUnmount() {
    this.unsubscribe();
  }

  observer = (snapshot) => {
    let docs = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    this.setState({ markers: docs });
  };

  render() {
    return (
      <MarkerContext.Provider value={this.state.markers}>
        {this.props.children}
      </MarkerContext.Provider>
    );
  }
}

export default MarkerProvider;
