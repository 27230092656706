import React, { forwardRef, useEffect } from "react";
import ReactDOM from "react-dom";
import "styles/modal.css";

const Modal = forwardRef((props, ref) => {
  const { children, onClose } = props;
  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    document.querySelector("html").classList.add("scroll-lock");

    return () => {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
      document.querySelector("html").classList.remove("scroll-lock");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const closeOnEscapeKeyDown = (e) => {
    if ((e.charCode || e.keyCode) === 27) {
      closeModal();
    }
  };

  function closeModal() {
    onClose();
  }

  return ReactDOM.createPortal(
    <aside className="modal__wrapper" onClick={() => {}} ref={ref}>
      <div className="modal__area" onClick={(e) => e.stopPropagation()}>
        <div className="modal__header">
          <span className="close-icon" onClick={closeModal}>
            <svg
              className="close"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
            </svg>
          </span>
        </div>
        <div className="modal__body scroll">{children}</div>
      </div>
    </aside>,
    document.body
  );
});

export default Modal;
