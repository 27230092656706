import tree_yellow from "icons/tree_yellow.svg";
import tree_red from "icons/tree_red.svg";
import tree_blue from "icons/tree_blue.svg";
import tree_green from "icons/tree_green.svg";
import tree_grey from "icons/tree_grey.svg";
import pin from "icons/pin.svg";
import pin_active from "icons/pin_active.svg";
import location from "icons/location.svg";
import tree_lightBrown from "icons/tree_lightBrown.svg";
import tree_lightGreen from "icons/tree_lightGreen.svg";

export const icons = {
  YW: tree_yellow,
  RD: tree_red,
  BL: tree_blue,
  GN: tree_green,
  GY: tree_grey,
  LBW: tree_lightBrown,
  LGN: tree_lightGreen,
  pin: pin,
  pin_active: pin_active,
  location: location,
};
