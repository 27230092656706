import React, { useContext, useEffect, useState } from "react";
import { GoogleMap, Marker } from "@react-google-maps/api";
import useMapState from "../../hooks/useMapState";
import MarkerContext from "../providers/markerContext";
import SideWindow from "../common/sideWindow";
import InfoCard from "./infoCard";
import ToolBar from "./toolBar";
import { icons } from "icons/markers";
import TreeCounter from "./treeCounter";

const Map = () => {
  const markers = useContext(MarkerContext);
  const [state, dispatch] = useMapState();
  const [map, setMap] = useState(null);

  const options = {
    fullscreenControl: false,
    mapTypeControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
    },
    zoomControl: true,
    zoomControlOptions: {
      position: window.google.maps.ControlPosition.RIGHT_CENTER,
    },
    scaleControl: true,
    streetViewControl: false,
    streetViewControlOptions: {
      position: window.google.maps.ControlPosition.BOTTOM_RIGHT,
    },
  };
  useEffect(() => {
    getCurrentLocation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getCurrentLocation() {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        if (map) {
          map.zoom = 15;
          map.panTo(pos);
        }
        dispatch(["SET_LOC", pos]);
      },
      (err) => console.log(err)
    );
  }

  function onMapClick({ latLng }) {
    let pos = { lat: latLng.lat(), lng: latLng.lng() };
    dispatch(["MAP_CLICK", pos]);
  }

  function onMarkerClick({ latLng }) {
    let pos = { lat: latLng.lat(), lng: latLng.lng() };
    let zoom = map.getZoom();

    map.panTo(latLng);
    if (zoom < 17) map.setZoom(17);
    dispatch(["MARKER_CLICK", pos]);
  }
  function onTreeClick(m) {
    map.panTo(m.position);
    let zoom = map.getZoom();
    if (zoom < 17) map.setZoom(17);
    dispatch(["TREE_CLICK", m]);
  }
  function handleClose() {
    dispatch(["CLOSE_INFO"]);
  }
  return (
    <div className="position-relative">
      <GoogleMap
        center={state.center}
        mapContainerStyle={containerStyle}
        zoom={13}
        onClick={onMapClick}
        onLoad={(m) => setMap(m)}
        onUnmount={() => setMap(null)}
        options={options}
      >
        {markers.map((m, i) => (
          <div key={i}>
            <Marker
              icon={icons[m.statusColor]}
              position={m.position}
              onClick={() => onTreeClick(m)}
            />
          </div>
        ))}
        {state.selectedPosition && (
          <div>
            <Marker
              position={state.selectedPosition}
              onClick={onMarkerClick}
              icon={state.showInfo ? icons.pin_active : icons.pin}
            />
          </div>
        )}
        {state.location && (
          <Marker
            position={state.location}
            icon={icons.location}
            clickable={false}
          />
        )}
        <SideWindow onClose={handleClose} show={state.showInfo}>
          {state.showInfo && (
            <InfoCard
              position={state.selectedMarker}
              treeId={state.treeId}
              onTreeUpdate={(pos) => map.panTo(pos)}
            />
          )}
        </SideWindow>
        <ToolBar
          position={state.coord}
          setCurrentLocation={() => {
            map.setZoom(14);
            getCurrentLocation();
          }}
          onChange={(e) => dispatch(["COORD_CHANGE", e.target])}
          setLocation={() => {
            map.panTo(state.coord);
            dispatch(["SET_COORD"]);
          }}
        />
      </GoogleMap>
      <TreeCounter />
    </div>
  );
};

const containerStyle = {
  width: "100%",
  height: "calc(100vh - var(--h))",
};

export default React.memo(Map);
