import PhotoLoader from "components/common/photoLoader";
import React from "react";
const TreeStatus = ({ code, status }) => {
  return (
    <>
      {code === 11 && (
        <div>
          <em className="text-secondary">Waiting for Location confirmation</em>
        </div>
      )}
      {code === 12 && (
        <div className="d-flex justify-content-between gap-2">
          {"Spotter: " + (status.spotter ? status.spotter.name : "N/A")}

          {status.spotter && <PhotoLoader uid={status.spotter.uid} />}
        </div>
        // <div>
        //   <em className="text-secondary">Location confirmed</em>
        // </div>
      )}

      {code === 21 && (
        <div>
          <em className="text-secondary">Waiting for caretaker confirmation</em>
        </div>
      )}
      {code === 22 && (
        <div className="d-flex justify-content-between gap-2 my-2">
          {"Caretaker: " + (status.caretaker ? status.caretaker.name : "N/A")}
          {status.caretaker && <PhotoLoader uid={status.caretaker.uid} />}
        </div>
        // <div>
        //   <em className="text-secondary">Tree is ready to plant</em>
        // </div>
      )}
      {code === 31 && (
        <div>
          <em className="text-secondary">Waiting for plant confirmation</em>
        </div>
      )}
      {code === 32 && (
        <div className="d-flex justify-content-between gap-2">
          {"Planter:" + (status.planter ? status.planter.name : "N/A")}
          {status.planter && <PhotoLoader uid={status.planter.uid} />}
        </div>
        // <div>
        //   <em className="text-secondary">Tree is planted</em>
        // </div>
      )}
      {code === 51 && (
        <div>
          <em className="text-secondary">Tree Planted, Yet to be Confirmed</em>
        </div>
      )}
      {code === 52 && (
        <div>
          <em className="text-secondary">Tree Planted</em>
        </div>
      )}
      {code === 20 && (
        <div>
          <em className="text-secondary">Caretaker Rejected</em>
        </div>
      )}
      {code === 30 && (
        <div>
          <em className="text-secondary">Plant Rejected</em>
        </div>
      )}
    </>
  );
};

export default TreeStatus;
