import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

// For Production
const firebaseConfig = {
  apiKey: "AIzaSyC4o_biz4lU3li2JtrZl7MzTdY1_g1u_8I",
  authDomain: "moretreesnow-6cf94.firebaseapp.com",
  projectId: "moretreesnow-6cf94",
  storageBucket: "moretreesnow-6cf94.appspot.com",
  messagingSenderId: "919850689071",
  appId: "1:919850689071:web:a7c472196945adf96a1cda",
  measurementId: "G-DLS13EZ2D4",
};

// For Staging
// const firebaseConfig = {
//   apiKey: "AIzaSyDbCMHUGXQTYQE-ptav87FL8Js76BpdLGo",
//   authDomain: "vernal-mantra-315200.firebaseapp.com",
//   projectId: "vernal-mantra-315200",
//   storageBucket: "vernal-mantra-315200.appspot.com",
//   messagingSenderId: "386584691503",
//   appId: "1:386584691503:web:efd2e07c14b2a533ec17da",
//   measurementId: "G-6VDGXG9VLW",
// };

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

export const auth = firebase.auth();

export const storage = firebase.storage();
