import React from "react";
export const FilterButton = ({
  options,
  name,
  value,
  label,
  onFilterChange,
  ...rest
}) => {
  return (
    <div>
      {label && <div>{label}</div>}
      <div className="btn-group">
        {options.map((o) => (
          //   <label
          //     //eslint-disable-next-line
          //     className={o.id == value ? "btn btn-primary" : "btn btn-light"}
          //     key={o.id}
          //   >
          <button
            key={o.id}
            {...rest}
            type="btn"
            name={name}
            id={o.id}
            value={o.id}
            className={o.id == value ? "btn btn-primary" : "btn btn-light"}
            onClick={() => {
              onFilterChange(o.id);
            }}
            //eslint-disable-next-line
          >
            {o.name}
          </button>
          // {o.name}
          //   </label>
        ))}
      </div>
    </div>
  );
};
