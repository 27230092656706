import React, { useState } from "react";
import Table from "components/common/table";
import { getStatus, tpaAction, getTPAUid } from "services/tpa";
import treeService from "services/trees";
import Radio from "components/common/radio";
import SearchBox from "components/common/searchBox";

const TreeDashboard = ({ trees, getUser, currentUser: { uid, name } }) => {
  const [seleted, setFilterField] = useState("0");
  const [searchValue, setSearchValue] = useState("");
  const options = [
    { id: "0", name: "All" },
    { id: "1", name: "Spotted" },
    { id: "2", name: "Location Confirmed" },
    { id: "3", name: "Waiting For Volunteers" },
    { id: "4", name: "Ready To Plant" },
    { id: "5", name: "Planted, Yet to Confirm" },
    { id: "6", name: "Planted" },
    { id: "7", name: "Dead" },
  ];

  const getContent = (tree) => {
    let user = getTPAUid(tree);

    // if (statusCodes.includes(32)) uid = tree.status.confirmPlant;
    // if (statusCodes.includes(31) || statusCodes.includes(22))
    //   uid = tree.status.confirmPlant;
    // if (statusCodes.includes(12) || statusCodes.includes(21))
    //   uid = tree.status.confirmPlant;

    // switch (code) {
    //   case 12:
    //     uid = tree.status.confirmLoc;
    //     break;
    //   case 21:
    //     uid = tree.status.confirmLoc;
    //     break;
    //   case 31:
    //     uid = tree.status.confirmCT;
    //     break;
    //   case 22:
    //     uid = tree.status.confirmCT;
    //     break;
    //   case 32:
    //     uid = tree.status.confirmPlant;
    //     break;
    //   case 20:
    //     uid = tree.status.confirmCT;
    //     break;
    //   case 30:
    //     uid = tree.status.confirmPlant;
    //     break;
    //   default:
    //     uid = "";
    //     break;
    // }

    // let name = getUser(uid)?.name;
    return "TPA: " + (user ? (user.name ? user.name : "N/A") : "N/A");
  };

  const getButtons = (t, code) => {
    return (
      <div>
        <i
          className="badge rounded-pill bg-primary pointer  material-icons mx-2"
          onClick={() => {
            tpaAction(true, t, uid, name, code);
          }}
        >
          check
        </i>
        <i
          className="badge rounded-pill bg-secondary pointer material-icons"
          onClick={() => {
            tpaAction(false, t, uid, name, code);
          }}
        >
          close
        </i>
      </div>
    );
  };

  const columns = [
    {
      path: "sl",
      label: "Sl.",
      content: (i, j) => j + 1,
    },
    {
      path: "treeName",
      label: "Name",
    },
    {
      path: "lat",
      label: "Lat",
      content: (t) => t.position.lat,
    },
    {
      path: "lng",
      label: "Lng",
      content: (t) => t.position.lng,
    },
    {
      path: "contributedTo",
      label: "Contributed To",
    },
    {
      path: "comment",
      label: "Comment",
    },
    {
      path: "institution",
      label: "Institution",
    },
    // {
    //   path: "spotterName",
    //   label: "Spotter",
    //   content: (t) => (
    //     <div>
    //       <div>{t.status.spotterName}</div>
    //       <div>{getContent(t.status.confirmLocName, t.status.code)}</div>
    //     </div>
    //   ),
    // },
    // {
    //   path: "CaretakerName",
    //   label: "Caretaker",
    //   content: (t) => (
    //     <div>
    //       <div>{t.status.caretakerName}</div>

    //       <div>{getContent(t.status.confirmCTName, t.status.code)}</div>
    //     </div>
    //   ),
    // },
    // {
    //   path: "planterName",
    //   label: "Planter",
    //   content: (t) => (
    //     <div>
    //       <div>{t.status.planterName}</div>
    //       <div>{getContent(t.status.confirmPlantName, t.status.code)}</div>
    //     </div>
    //   ),
    // },
    {
      path: "user",
      label: "User",
      content: (t) => (
        <div>
          <div className="d-flex ">
            Spotter:{" "}
            {t.status.spotter && t.status.spotter.name
              ? t.status.spotter.name
              : "N/A"}
            {t.status.statusCodes.includes(11) && getButtons(t, 11)}
          </div>
          <div className="d-flex ">
            Caretaker:{" "}
            {t.status.caretaker && t.status.caretaker.name
              ? t.status.caretaker.name
              : "N/A"}
            {t.status.statusCodes.includes(21) && getButtons(t, 21)}
          </div>
          <div className="d-flex ">
            Planter:{" "}
            {t.status.planter && t.status.planter.name
              ? t.status.planter.name
              : "N/A"}
            {t.status.statusCodes.includes(31) && getButtons(t, 31)}
          </div>

          <div>{getContent(t)}</div>
          {t.status.statusCodes.includes(51) && (
            <div className="d-flex ">
              Approve Planted Tree:{" "}
              {/* {t.status.planterName ? t.status.planterName : "N/A"} */}
              {getButtons(t, 51)}
            </div>
          )}
        </div>
      ), //getUser(getStatus(t.status).uid)?.name,
    },
    // {
    //   path: "status",
    //   label: "Status",
    //   content: (t) => getStatus(t.status).name,
    // },
    // {
    //   path: "btn",
    //   label: "",
    //   content: (t) =>
    //     (t.status.code % 10 === 1 || t.status.code % 10 === 0) &&
    //     t.status.code !== 20 &&
    //     t.status.code !== 30 && (
    //       <div>
    //         <i
    //           className="btn btn-primary btn-sm material-icons p-1 m-1"
    //           onClick={() => {
    //             tpaAction(true, t, uid, name);
    //           }}
    //         >
    //           check
    //         </i>
    //         <i
    //           className="btn btn-secondary btn-sm material-icons p-1 m-1"
    //           onClick={() => {
    //             tpaAction(false, t, uid, name);
    //           }}
    //         >
    //           close
    //         </i>
    //       </div>
    //       // <button
    //       //   className="btn btn-sm btn-primary"
    //       //   onClick={() => tpaAction(true, t, uid)}
    //       // >
    //       //   Confirm
    //       // </button>
    //     ),
    //   // style: { width: "1%" },
    // },
    // {
    //   path: "btn",
    //   label: "",
    //   content: (t) =>
    //     t.status.statusCodes.includes(51) && (
    //       <div>
    //         <button
    //           className="btn btn-sm btn-primary mx-2"
    //           onClick={() => tpaAction(true, t, uid, name, 51)}
    //         >
    //           Confirm
    //         </button>
    //         <button
    //           className="btn btn-sm btn-secondary"
    //           onClick={() => tpaAction(false, t, uid, name, 51)}
    //         >
    //           Reject
    //         </button>
    //       </div>
    //     ),
    // },
    {
      path: "btn2",
      label: "",
      content: (t) => (
        // (t.status.code % 10 === 1 ||
        //   t.status.code % 10 === 2 ||
        //   t.status.code === 20 ||
        //   t.status.code == 30) &&
        // t.status.code !== 32 && (
        <i
          className="btn btn-danger material-icons p-1"
          onClick={() => {
            if (window.confirm("Are you sure? Marker will be removed."))
              treeService.treeAction("DELETE", { id: t.id });
          }} //tpaAction(false, t, uid)
        >
          delete
        </i>
      ),
      // ),
      style: { width: "1%" },
    },
  ];

  const filterTrees = () => {
    let filtered = trees;
    if (seleted === "0") {
      filtered = trees;
    } else
      filtered = trees.filter((f) => {
        if (seleted == "7") return f.statusColor === "GY";
        if (seleted == "6") return f.statusColor === "GN";
        if (seleted == "5") return f.statusColor === "LGN";
        if (seleted == "4") return f.statusColor === "BL";
        if (seleted == "3") return f.statusColor === "LBW";
        if (seleted == "2") return f.statusColor === "RD";
        if (seleted == "1") return f.statusColor === "YW";
      });

    if (searchValue) {
      filtered = filtered.filter((f) =>
        f.treeName.toLowerCase().includes(searchValue.toLowerCase().trim())
      );
    }
    return filtered;
  };

  let filtered = filterTrees();

  const rowClassName = (index) => {
    let statusColor = filtered[index].statusColor;
    switch (statusColor) {
      case "YW":
        return "bg-yellow";
      case "RD":
        return "bg-red";
      case "LBW":
        return "bg-lightBrown";
      case "BL":
        return "bg-blue";
      case "LGN":
        return "bg-lightGreen";
      case "GN":
        return "bg-green";
      case "GY":
        return "bg-grey";

      default:
        break;
    }
  };

  return (
    <>
      <div className="d-flex flex-wrap">
        <div className="" style={{ maxWidth: "350px" }}>
          <SearchBox
            placeholder="Search By Tree Name"
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
          />
        </div>
        <Radio
          options={options}
          name="filter"
          value={seleted}
          onChange={(e) => {
            setFilterField(e.target.value);
          }}
        />
      </div>
      <Table columns={columns} data={filtered} rowClassName={rowClassName} />
    </>
  );
};

export default TreeDashboard;
