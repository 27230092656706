import React from "react";

const Radio = ({ name, value, options, onChange, label, inline }) => {
  return (
    <div className="d-flex flex-wrap m-2">
      {label && <div>{label}</div>}
      {options.map((o, i) => (
        <div
          key={i}
          className={`m-1 form-check ${inline ? "form-check-inline" : ""}`}
        >
          <input
            id={o.id}
            type="radio"
            name={name}
            value={o.id}
            checked={o.id.toString() === value.toString()}
            onChange={onChange}
            className="form-check-input"
          />
          <label className="form-check-label" htmlFor={o.id}>
            {o.name}
          </label>
        </div>
      ))}
    </div>
  );
};

export default Radio;
