import React from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import Map from "./map";

const API_KEY = "AIzaSyBJG0wfV7BUxZ193vyP34445KX8LschYV8"; // Production
// const API_KEY = "AIzaSyCqeqOQASgsIH-_3FEeZt3LBKpCCKIXW1U"; // Staging

function MapLoader() {
  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: API_KEY,
  });
  const loading = loadError ? "Error Loading" : "Loading...";

  return isLoaded ? <Map /> : <p>{loading}</p>;
}

export default MapLoader;
