import React, { Component } from "react";
import AuthContext from "components/providers/authContext";
import MarkerContext from "components/providers/markerContext";
import UserDashboard from "./userDashboard";
import TreeDashboard from "./treeDashboard";
import userService from "services/users";
import { approveUser } from "services/tpa";
import { db } from "services/firebase";
import { Redirect } from "react-router-dom";
const Dashboard = () => {
  return (
    <AuthContext.Consumer>
      {(auth) => (
        <MarkerContext.Consumer>
          {(markers) =>
            !auth.user ||
            (!auth.user.roles.TPA &&
              !auth.user.roles["Super Admin"] &&
              !auth.user.roles["Super User"]) ? (
              <Redirect to="/" />
            ) : (
              <DashboardClass currentUser={auth.user} markers={markers} />
            )
          }
        </MarkerContext.Consumer>
      )}
    </AuthContext.Consumer>
  );
};
export default Dashboard;

class DashboardClass extends Component {
  state = {
    trees: [],
    users: [],
    allUsers: [],
    selected: "trees",
    selectedFilter: "My Zone",
  };

  componentDidMount() {
    const { currentUser } = this.props;
    this.getUsersForApproval(currentUser.zip);
    this.getTreesForApproval(currentUser.zip);
  }

  componentWillUnmount() {
    this.unsubUsers();
    this.unsubTrees();
  }

  getTreesForApproval = (zip) => {
    const { currentUser } = this.props;
    const query = currentUser.roles["Super Admin"]
      ? db.collection("trees")
      : db.collection("trees").where("zip", "==", zip);
    this.unsubTrees = query.onSnapshot((snapshot) => {
      let trees = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      this.setState({ trees });
    });
  };

  getUsersForApproval = (zip) => {
    const { currentUser } = this.props;
    const query = db.collection("users");
    this.unsubUsers = query.onSnapshot(async (snapshot) => {
      let allUsers = [];
      let uids = [];

      snapshot.docs.forEach((doc) => {
        let data = doc.data();
        let u = { uid: doc.id, ...data };
        allUsers.push(u);

        if (data.zip === zip || currentUser.roles["Super Admin"]) {
          uids.push(doc.id);
        }
      });
      const { data } = await userService.getUserClaims(uids);
      if (!data) return;
      let claims = data.filter(
        (u) => u.uid != currentUser.uid && u.uid != null
      );
      // if (currentUser.roles["Super Admin"]) {
      // claims = data.filter((u) => u.uid != currentUser.uid && u.uid != null);

      // } else {
      //   //   (u.roles?.Planter || u.roles?.Caretaker || u.roles?.TPA)
      //   claims = data.filter((u) => u.uid != currentUser.uid && u.uid != null);
      // }
      const users = claims.map((u) => {
        let ud = allUsers.find((f) => f.uid === u.uid);
        return { ...ud, ...u };
      });
      this.setState({ users, allUsers });
    });
  };

  approveUser = (uid) => {
    approveUser(uid);
    let users = this.state.users.map((u) =>
      u.uid === uid ? { ...u, approved: true } : u
    );
    this.setState({ users });
  };

  getUserById = (uid) => {
    const user = this.state.allUsers.find((u) => u.uid === uid);
    return user;
  };

  onUserRoleUpdate = (userData, roles) => {
    let users = [...this.state.users];
    let user = users.find((u) => u.uid == userData.uid);
    user.roles = roles;
    this.setState({ users });
  };

  onUserDelete = (uid, disable) => {
    let users = [...this.state.users];
    if (disable) {
      let found = users.find((f) => f.uid === uid);
      if (found) found["disabled"] = true;
    } else {
      users = users.filter((f) => f.uid != uid);
    }
    this.setState({ users });
  };

  onEnableUser = (uid) => {
    let users = [...this.state.users];

    let found = users.find((f) => f.uid === uid);
    if (found) found["disabled"] = false;
    this.setState({ users });
  };

  filterUser = () => {
    const { users, selectedFilter } = this.state;
    let filtered = [...users];
    const { currentUser } = this.props;
    if (selectedFilter === "My Zone")
      filtered = users.filter((f) => currentUser.zip == f.zip);
    return filtered;
  };

  onFilterChange = (value) => {
    this.setState({ selectedFilter: value });
  };

  render() {
    const { users, trees, selected, selectedFilter } = this.state;
    const { currentUser } = this.props;

    return (
      <>
        <ul className="nav nav-tabs nav-fill mb-2">
          <li className="nav-item">
            <div
              className={selected === "trees" ? "nav-link active" : "nav-link"}
              onClick={() => this.setState({ selected: "trees" })}
            >
              Trees
            </div>
          </li>
          <li className="nav-item">
            <div
              className={selected === "users" ? "nav-link active" : "nav-link"}
              onClick={() => this.setState({ selected: "users" })}
            >
              Users
            </div>
          </li>
        </ul>
        {/* <nav className="nav nav-tabs nav-fill mb-2">
          <li className="nav-item">
            <button
              className={`nav-link ${selected === "trees" ? "active" : ""}`}
              onClick={() => this.setState({ selected: "trees" })}
            >
              Trees
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link ${selected === "users" ? "active" : ""}`}
              onClick={() => this.setState({ selected: "users" })}
            >
              Users
            </button>
          </li>
        </nav> */}
        <div className="mx-2">
          {selected === "trees" && (
            <TreeDashboard
              trees={trees}
              getUser={this.getUserById}
              currentUser={currentUser}
            />
          )}
          {selected === "users" && (
            <UserDashboard
              currentUser={currentUser}
              users={
                currentUser.roles["Super Admin"] ? this.filterUser() : users
              }
              approveUser={this.approveUser}
              onUserRoleUpdate={this.onUserRoleUpdate}
              onFilterChange={this.onFilterChange}
              selectedFilter={selectedFilter}
              onUserDelete={this.onUserDelete}
              onEnableUser={this.onEnableUser}
            />
          )}
        </div>
      </>
    );
  }
}
