import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

const Table = ({
  columns,
  sortColumn,
  onSort,
  data,
  enableSort,
  rowClassName,
}) => {
  return (
    <div className="table-responsive">
      <table className="table table-bordered align-middle">
        <TableHeader
          columns={columns}
          sortColumn={sortColumn}
          onSort={onSort}
          enableSort={enableSort}
        />
        <TableBody columns={columns} data={data} getClassName={rowClassName} />
      </table>
    </div>
  );
};

export default Table;
