import React from "react";
import Form from "../common/form";
import AuthContext from "../providers/authContext";
import { Redirect, Link } from "react-router-dom";
import Loader from "components/common/loader";
import schemas from "./schemas";

class Login extends Form {
  state = {
    data: {
      email: "",
      password: "",
    },
    errors: {},
  };
  schema = () => ({
    ...schemas.authSchema,
  });

  doSubmit = async () => {
    const { email, password } = this.state.data;
    const { location, history } = this.props;
    this.setState({ loading: true });
    let r = await this.context.login(email, password);
    if (r) {
      history.replace({
        pathname: "/help",
        state: { background: location.state?.background },
      });
    } else alert("Login Failed !");
    this.setState({ loading: false });
  };

  render() {
    const { location } = this.props;
    const { user } = this.context;
    return (
      <div>
        <Loader active={this.state.loading} />
        {user && <Redirect to="/" />}

        <h4 className="a-center mb-2">Login</h4>
        <form style={{ minWidth: "20ch" }}>
          {this.renderInput("email", "Email")}
          {this.renderPassword("password", "Password", {
            isShowable: true,
          })}
          <div className="mb-2"></div>
          <div className="row">
            <div className="col-3">{this.renderButton("Login")}</div>
            <div className="col">
              <Link
                to={{
                  pathname: "/forgotPassword",
                  state: { background: location.state?.background },
                }}
                replace
              >
                Forgot Password
              </Link>
            </div>
          </div>
        </form>
        <p>
          Don't have an account?{" "}
          <Link
            to={{
              pathname: "/register",
              state: { background: location.state?.background },
            }}
            className="link"
            replace
          >
            Register
          </Link>
        </p>
      </div>
    );
  }
}
Login.contextType = AuthContext;
export default Login;
