import React, { useState } from "react";

const Password = React.forwardRef((props, ref) => {
  const {
    name,
    label,
    error,
    hintText,
    isShowable = false,
    onKeyPress = () => {},
    ...rest
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="input-width">
      {label && <label htmlFor={name}>{label}</label>}
      <div className="d-flex align-items-center form-control sm">
        <input
          {...rest}
          name={name}
          id={name}
          className="box flex-grow-1"
          placeholder={hintText}
          ref={ref}
          type={showPassword ? "text" : "password"}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.currentTarget.blur();
              onKeyPress(e);
            }
          }}
        />
        {isShowable && (
          <i
            type="button"
            className="ms-1 material-icons"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? "visibility" : "visibility_off"}
          </i>
        )}
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
    </div>
  );
});

export default Password;
