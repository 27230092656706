import React from "react";
import Form from "./../common/form";
import Joi from "joi";
import AuthContext from "../providers/authContext";
import RoleSelector from "./roleSelector";
import Address from "components/common/address";
import ImageUpload from "components/common/imageUpload";
import { Redirect, Link } from "react-router-dom";
import Loader from "components/common/loader";
import schemas from "./schemas";

class Register extends Form {
  state = {
    data: {
      email: "",
      password: "",
      confirmPassword: "",
      name: "",
      zip: "",
    },
    address: {
      address: "",
      city: "",
      state: "",
      phone: "",
    },
    roles: {
      Spotter: false,
      Planter: false,
      Caretaker: false,
      // Financier: false,
    },
    TPA: false,
    errors: {},
    page: false,
  };

  schema = () => ({
    ...schemas.authSchema,
    confirmPassword: Joi.string()
      .valid(this.state.data.password)
      .label("Confirm password")
      .messages({ "any.only": "{{#label}} does not match" }),
    ...schemas.commonSchema,
  });

  onRoleChange = ({ target }) => {
    let roles = { ...this.state.roles };
    roles[target.name] = !roles[target.name];
    this.setState({ roles });
  };

  selectAll = (value) => {
    let roles = { ...this.state.roles };
    for (let key in roles) roles[key] = value;
    this.setState({ roles });
  };

  onAddressChange = ({ target: { name, value } }) => {
    let address = { ...this.state.address };
    address[name] = value;
    this.validateProperty(name, value, schemas.addressSchema[name]);
    this.setState({ address });
  };
  onTpaChange = () => {
    this.selectAll(false);
    this.setState({ TPA: !this.state.TPA });
  };

  togglePage = () => {
    const { page, roles, data, TPA } = this.state;
    if (page) return this.setState({ page: !page });

    const { name, zip, ...rest } = this.schema();
    const errors = this.validate(data, rest);
    if (!Object.values(roles).some((r) => r) && !TPA) {
      errors.roles = "Select atleast one Role";
      this.setState({ errors });
      return;
    }
    if (Object.keys(errors).length === 0) this.setState({ page: !page });
  };

  doSubmit = async () => {
    const {
      data: { email, password, name, zip },
      roles,
      address,
      TPA,
      photo,
    } = this.state;

    let errors = {};
    if (TPA || roles.Planter || roles.Caretaker)
      errors = this.validate(address, schemas.addressSchema);
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await this.context.register(
        email,
        password,
        { ...roles, TPA },
        {
          name,
          email,
          zip,
          address,
        },
        photo
      );
      this.setState({ loading: false });
      const { location, history } = this.props;
      history.replace({
        pathname: "/login",
        state: { background: location.state?.background },
      });
    }
  };
  handleImage = (file) => {
    this.setState({
      preview: URL.createObjectURL(file),
      photo: file,
    });
  };

  render() {
    const { roles, address, page, errors, TPA, preview, loading } = this.state;
    const { location } = this.props;
    const { user } = this.context;
    return (
      <div>
        <Loader active={loading} />
        {user && <Redirect to="/" />}
        <h4 className="a-center mb-2">Register</h4>
        <form>
          {!page ? (
            <div>
              {this.renderInput("email", "Email *", { autoComplete: "off" })}
              {this.renderPassword("password", "Password *", {
                isShowable: true,
                autoComplete: "new-password",
              })}
              {this.renderInput("confirmPassword", "Confirm Password *", {
                type: "password",
                autoComplete: "new-password",
              })}
              <RoleSelector
                roles={roles}
                onChange={this.onRoleChange}
                selectAll={this.selectAll}
                error={errors.roles}
                onTpaChange={this.onTpaChange}
                tpa={TPA}
              />
            </div>
          ) : (
            <div>
              <ImageUpload
                onChange={this.handleImage}
                src={preview}
                label="Profile Photo"
              />
              {this.renderInput("name", "Name *")}
              {this.renderInput("zip", "Pincode/Zip *")}
              <Address
                address={address}
                onChange={this.onAddressChange}
                errors={errors}
                required={TPA || roles.Caretaker || roles.Planter}
              />
            </div>
          )}
          <div className="d-flex justify-content-between mt-2">
            <button
              type="button"
              className="btn btn-sm btn-primary"
              onClick={this.togglePage}
            >
              {!page ? "Next" : "Back"}
            </button>
            {page && this.renderButton("Register")}
          </div>
        </form>
        <p>
          Already have an account?{" "}
          <Link
            to={{
              pathname: "/login",
              state: { background: location.state?.background },
            }}
            className="link"
            replace
          >
            Log in
          </Link>
        </p>
      </div>
    );
  }
}
Register.contextType = AuthContext;
export default Register;
