import React from "react";
import AuthContext from "components/providers/authContext";
import Form from "../common/form";
import Address from "components/common/address";
import ImageUpload from "components/common/imageUpload";
import userService from "services/users";
import { Redirect } from "react-router-dom";
import schemas from "./schemas";
import Loader from "components/common/loader";

function Account(props) {
  return (
    <AuthContext.Consumer>
      {(auth) =>
        !auth.user ? (
          <Redirect to="/" />
        ) : (
          <AccountClass {...props} auth={auth} />
        )
      }
    </AuthContext.Consumer>
  );
}

export default Account;
class AccountClass extends Form {
  state = {
    data: {
      name: "",
      zip: "",
    },
    address: {
      address: "",
      state: "",
      city: "",
      phone: "",
    },
    roles: {},
    photo: "",
    errors: {},
  };
  schema = () => ({
    ...schemas.commonSchema,
  });

  componentDidMount() {
    let { name, zip, address, photoURL, roles } = this.props.auth.user;

    this.setState({
      data: { name: name, zip: zip },
      address: address,
      preview: photoURL,
      roles,
    });
  }

  onAddressChange = ({ target: { name, value } }) => {
    let address = { ...this.state.address };
    address[name] = value;
    this.validateProperty(name, value, schemas.addressSchema[name]);
    this.setState({ address });
  };

  handleImage = (file) => {
    this.setState({
      preview: URL.createObjectURL(file),
      photo: file,
    });
  };
  doSubmit = async () => {
    const { data, address, photo, roles } = this.state;

    let errors = {};
    if (roles.TPA || roles.Planter || roles.Caretaker)
      errors = this.validate(address, schemas.addressSchema);
    if (Object.keys(errors).length === 0) {
      this.setState({ loading: true });
      await userService.updateUser(
        null,
        {
          name: data.name,
          zip: data.zip,
          address,
        },
        photo
      );
      await this.props.auth.handleUser(null);
      this.setState({ loading: false });
    }

    this.props.history.goBack();
  };

  render() {
    const { roles, address, errors, preview, loading } = this.state;

    return (
      <div>
        <Loader active={loading} />
        <h5 className="m-2">My Account</h5>
        <form>
          <div className="d-flex align-items-start flex-wrap">
            <div className="me-3">
              <ImageUpload
                onChange={this.handleImage}
                src={preview}
                // label="Profile Photo"
              />
            </div>
            <div style={{ minWidth: "20ch" }}>
              {this.renderInput("name", "Name *")}
              {this.renderInput("zip", "Pincode/Zip *")}
              <Address
                address={address}
                onChange={this.onAddressChange}
                errors={errors}
                required={roles.TPA || roles.Caretaker || roles.Planter}
              />
              <div className="mt-2">{this.renderButton("Update")}</div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}
