import CheckBox from "components/common/checkBox";
import React from "react";

const RoleSelector = ({
  roles,
  onChange,
  selectAll,
  error,
  tpa,
  onTpaChange,
  hideTitle,
  superUser,
  setSuperUser,
  showSuperUser,
}) => {
  let all = Object.values(roles).every((val) => val);
  return (
    <>
      {!hideTitle && "Volunteer Role *"}
      <div className="grid-even">
        {Object.keys(roles).map(
          (k) =>
            k !== "Super Admin" &&
            k !== "Super User" &&
            k !== "TPA" && (
              <CheckBox
                key={k}
                checked={roles[k]}
                onChange={onChange}
                label={k}
                name={k}
                disabled={tpa}
              />
            )
        )}
        <CheckBox
          label="Financier"
          // onChange={() => selectAll(!all)}
          name="Financier"
          disabled
        />
        <CheckBox
          checked={all}
          label="Full Volunteer"
          onChange={() => selectAll(!all)}
          name="all"
          disabled={tpa}
        />
      </div>
      <div className="hl"></div>
      <CheckBox
        checked={tpa}
        label="Tree Plant Activist (TPA)"
        onChange={onTpaChange}
        name="TPA"
      />
      {showSuperUser && (
        <div>
          <div className="hl"></div>
          <CheckBox
            checked={superUser}
            label="Super User"
            onChange={({ target: { checked } }) => setSuperUser(checked)}
            name="Super User"
          />
        </div>
      )}
      {error && <div className="alert alert-danger">{error}</div>}
    </>
  );
};

export default RoleSelector;
