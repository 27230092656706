export const api = {
  // server: "http://localhost:2001/",
  server: "https://us-central1-moretreesnow-6cf94.cloudfunctions.net/api_node/", // Production
  // server:
  // "https://us-central1-vernal-mantra-315200.cloudfunctions.net/api_node/", // Staging
  register: "register",
  login: "login",
  getUserClaims: "getUserClaims",
  approveUser: "approveUser",
  updateUserRoles: "updateUserRoles",
  deleteUser: "deleteUser",
  enableUser: "enableUser",
};
