import React, { useContext, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "components/providers/authContext";
import NumberField from "../common/numberField";
import TreeDetails from "./treeDetails";
import TreeUpdate from "./treeUpdate";
import Joi from "joi";
import { treeAction, treeSnapshot } from "services/trees";
import { toast } from "react-toastify";

let listener = null;

const InfoCard = ({ position, treeId, onTreeUpdate }) => {
  const [pos, setPos] = useState({ lat: "", lng: "" });
  const [tree, setTree] = useState(null);
  const [errors, setErrors] = useState(null);

  const authContext = useContext(AuthContext);
  const { roles, zip, uid, approved, name } = authContext.user || {};

  const location = useLocation();

  const schema = Joi.object({
    lat: Joi.number().required().label("Lattitude"),
    lng: Joi.number().required().label("Longitude"),
    treeName: Joi.string().allow("").label("Tree Name"),
    localName: Joi.string().required().label("Name in Local Lang."),
    botanicalName: Joi.string().allow("").label("Botanical Name"),
    contributedTo: Joi.string().allow("").label("Contributed To"),
    comment: Joi.string().allow("").max(40).label("Comment"),
    institution: Joi.string().allow("").label("Institution"),
  });

  useEffect(() => {
    setPos(position);
    setErrors(null);
    getTreeDetails(treeId);
    return () => listener && listener();
  }, [position, treeId]);
  function getTreeDetails(treeId) {
    if (treeId)
      listener = treeSnapshot(treeId, (doc) => {
        if (doc.data()) setTree({ id: treeId, ...doc.data() });
        else setTree(null);
      });
    else setTree(null);
  }

  function validate(data) {
    const { error } = schema.validate(data);
    if (error) setErrors(error.message);
    else setErrors(null);
    return error;
  }

  async function addMarker(names, status) {
    const err = await validate({ ...names, ...pos });
    if (err) return;
    let tree = await treeAction("ADD", {
      ...names,
      position: pos,
      zip,
      status,
    });
    getTreeDetails(tree.id);
    onTreeUpdate(pos);
    toast.success("Marker Added Successfully", { position: "top-left" });
  }

  function updateMarker(names, status) {
    const error = validate({ ...names, ...pos });
    if (error) return;
    console.log({
      ...names,
      id: tree.id,
      position: pos,
      status,
    });
    treeAction("UPDATE", {
      ...names,
      id: tree.id,
      position: pos,
      status,
    });
    onTreeUpdate(pos);
    toast.success("Updated Successfully", { position: "top-left" });
  }

  function removeMarker() {
    let r = window.confirm("Are you sure to delete this tree?");
    if (r) {
      treeAction("DELETE", { id: tree.id });
      setTree(null);
    }
  }

  function onPositionChange({ target }) {
    let nextpos = { ...pos };
    nextpos[target.name] = Number(target.value);
    setPos(nextpos);
  }
  let readonly =
    !roles ||
    (tree &&
      !(
        roles.TPA ||
        (tree.status.code === 11 && roles.Spotter !== uid) ||
        roles["Super Admin"] ||
        roles["Super User"]
      ));
  return (
    <div className="position-relative">
      {errors && <div className="alert alert-danger sticky">{errors}</div>}
      <NumberField
        style={{ width: "100%" }}
        label="Lattitude"
        name="lat"
        value={pos.lat}
        onChange={onPositionChange}
        readOnly={readonly}
      />
      <NumberField
        style={{ width: "100%" }}
        label="Longitude"
        name="lng"
        value={pos.lng}
        onChange={onPositionChange}
        readOnly={readonly}
      />

      {roles && (
        <TreeUpdate
          tree={tree}
          roles={roles}
          addMarker={addMarker}
          updateMarker={updateMarker}
          removeMarker={removeMarker}
          uid={uid}
          zip={zip}
          userName={name}
          approved={approved}
          readonly={readonly}
        />
      )}
      {tree && !roles && <TreeDetails tree={tree} />}

      {!roles && (
        <p>
          <Link
            to={{
              pathname: "/login",
              state: { background: location },
            }}
            className="link"
          >
            Log In{" "}
          </Link>
          to make changes
        </p>
      )}
    </div>
  );
};

export default InfoCard;
