import CheckBox from "components/common/checkBox";
import React from "react";

const RoleActions = ({
  roles,
  tree,
  status,
  setStatus,
  uid,
  zip,
  userName,
}) => {
  function removeElement(array, value) {
    return array.filter((v) => v != value);
  }

  function checkStatusCode(array, code) {
    let found = array.find((a) => code === a || code + 1 === a);
    return found;
  }

  function handleStatus({ target }, code) {
    let nextStatus = { ...status };

    // nextStatus[target.name] = nextStatus[target.name] ? null : uid;
    // nextStatus.code = target.checked ? code : tree.status.code;
    if (target.checked) {
      nextStatus[target.name] = { uid: uid, name: userName };

      nextStatus.statusCodes.push(code);
      if (roles.TPA || roles["Super Admin"] || roles["Super User"]) {
        nextStatus.statusCodes = removeElement(
          nextStatus.statusCodes,
          code - 1
        );
      }
    } else {
      nextStatus[target.name] = null;
      if (roles.TPA || roles["Super Admin"] || roles["Super User"]) {
        nextStatus.statusCodes.push(code - 1);
      }
      nextStatus.statusCodes = removeElement(nextStatus.statusCodes, code);
    }
    setStatus(nextStatus);
  }
  const checkValue = (data) => {
    return data ? data.uid : false;
  };

  let { statusCodes } = tree.status;
  return (
    <>
      {(roles["Super Admin"] ||
        (roles["Super User"] && tree.zip === zip) ||
        (tree.zip === zip && roles.TPA)) &&
        statusCodes.includes(11) && (
          <CheckBox
            checked={Boolean(checkValue(status.confirmLoc))}
            label="Confirm Location"
            name="confirmLoc"
            onChange={(e) => handleStatus(e, 12)}
          />
        )}

      {(roles["Super Admin"] || roles["Super User"] || roles.Caretaker) &&
        statusCodes.includes(12) &&
        !checkStatusCode(statusCodes, 21) && (
          <CheckBox
            checked={Boolean(checkValue(status.caretaker))}
            label="Become Caretaker"
            name="caretaker"
            onChange={(e) => handleStatus(e, 21)}
          />
        )}

      {(roles["Super Admin"] ||
        (roles["Super User"] && tree.zip === zip) ||
        (tree.zip === zip && roles.TPA)) &&
        statusCodes.includes(21) && (
          <CheckBox
            checked={Boolean(checkValue(status.confirmCT))}
            label="Confirm Caretaker"
            name="confirmCT"
            onChange={(e) => handleStatus(e, 22)}
          />
        )}

      {(roles["Super Admin"] || roles["Super User"] || roles.Planter) &&
        statusCodes.includes(12) &&
        !checkStatusCode(statusCodes, 31) && (
          <CheckBox
            checked={Boolean(checkValue(status.planter))}
            label="Become Tree Planter"
            name="planter"
            onChange={(e) => handleStatus(e, 31)}
          />
        )}

      {(roles["Super Admin"] ||
        (roles["Super User"] && tree.zip === zip) ||
        (roles.TPA && tree.zip === zip)) &&
        statusCodes.includes(31) && (
          <>
            <CheckBox
              checked={Boolean(checkValue(status.confirmPlanter))}
              label="Confirm Tree Planter"
              name="confirmPlanter"
              onChange={(e) => handleStatus(e, 32)}
            />
          </>
        )}
      {(roles["Super Admin"] ||
        roles["Super User"] ||
        (roles.Planter &&
          tree.status.planter &&
          tree.status.planter.uid === uid)) &&
        statusCodes.includes(32) &&
        statusCodes.includes(22) &&
        !checkStatusCode(statusCodes, 51) && (
          <>
            <CheckBox
              checked={Boolean(checkValue(status.plantTree))}
              label="Plant Tree"
              name="plantTree"
              onChange={(e) => handleStatus(e, 51)}
            />
          </>
        )}
      {(roles["Super Admin"] ||
        (roles["Super User"] && tree.zip === zip) ||
        (tree.zip === zip && roles.TPA)) &&
        statusCodes.includes(51) && (
          <>
            <CheckBox
              checked={Boolean(checkValue(status.confirmPlantTree))}
              label="Confirm Plant Tree"
              name="confirmPlantTree"
              onChange={(e) => handleStatus(e, 52)}
            />
          </>
        )}
    </>
  );
};

export default RoleActions;
