import React from "react";
import NumberField from "../common/numberField";

const ToolBar = ({ setCurrentLocation, setLocation, position, onChange }) => {
  return (
    <>
      <span
        className="material-icons loc"
        onClick={setCurrentLocation}
        tabIndex="0"
        role="button"
      >
        my_location
      </span>
      <div className="d-flex gap-1 align-items-end toolbar">
        <NumberField
          hintText="Lattitude"
          name="lat"
          value={position.lat}
          onChange={onChange}
        />
        <NumberField
          hintText="Longitude"
          name="lng"
          value={position.lng}
          onChange={onChange}
        />
        <button className="btn btn-sm btn-primary" onClick={setLocation}>
          Go
        </button>
      </div>
    </>
  );
};

export default ToolBar;
