import React from "react";

const Help = ({ history }) => {
  return (
    <div>
      <h5>Help</h5>
      <p>Welcome to Moretreesnow.</p>
    </div>
  );
};

export default Help;
