import React from "react";
import preview from "icons/add-user.png";

const ImageUpload = ({ src, onChange, label }) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    if (file) onChange(file);
  };

  return (
    <div className="d-flex justify-content-start align-items-center">
      <input
        id="file"
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        accept="image/*"
        style={{ display: "none" }}
      />
      <img
        tabIndex="0"
        src={src || preview}
        alt={label}
        onClick={handleClick}
        className="img-rounded pointer me-2"
      ></img>
      <label htmlFor="file" className="text-break">
        {label}
      </label>
    </div>
  );
};

export default ImageUpload;
