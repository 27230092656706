import React from "react";
import { Link } from "react-router-dom";
import userService from "services/users";
import Joi from "joi";
import Form from "components/common/form";
export default class ForgottenPassword extends Form {
  state = {
    data: { email: "" },
    errors: {},
  };

  schema = () => ({
    email: Joi.string()
      .email({ tlds: { allow: false } })
      .label("Email"),
  });

  // handleChange = (e) => {
  //   this.setState({
  //     [e.target.name]: e.target.value,
  //   });
  // };

  doSubmit = async () => {
    const { location, history } = this.props;

    let userExist = await userService.getUserByEmail(this.state.data.email);
    console.log(userExist);
    if (userExist) {
      history.replace({
        pathname: "/login",
        state: { background: location.state?.background },
      });
    }
  };

  render() {
    const { location } = this.props;

    return (
      <div className="">
        <form>
          <h5> Forgotten your password? </h5>
          <p>
            No worries, just enter your email below and we will send you a link
            to reset it!
          </p>
          {/* <label htmlFor="email"> Email </label>
          <br />
          <input
            type="text"
            className="form-control"
            placeholder="email address"
            name="email"
            id="email"
            onChange={this.handleChange}
          />
          <br /> */}
          {this.renderInput("email", "Email")}

          <div className="d-flex justify-content-between mt-2">
            <Link
              to={{
                pathname: "/login",
                state: { background: location.state?.background },
              }}
              className="link"
              replace
            >
              Back To Login
            </Link>
            {this.renderButton("Submit")}
          </div>
        </form>
      </div>
    );
  }
}
